<!--原材料采购日报表-->
<template>
    <div class="purchaseDayReport table-bg">
        <reportTable
            code="PurchaseDay"
            :query="query"
            @generateComplete="generateComplete"
            :percentage="percentage"
            :toolbar="['exportTable']"
            :custom-date-method="customDateMethod"
            table-title="原材料采购日报表"
        >
            <template #queryItems>
                <div class="query-item">
                    <label class="name">日期选择：</label>
                    <el-date-picker
                        v-model="monthDate"
                        type="month"
                        placeholder="请选择"
                        value-format="yyyy-MM"
                        :editable="false"
                        :clearable="false"
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">出场日期：</label>
                    <el-date-picker
                        v-model="tareTime"
                        type="month"
                        placeholder="请选择"
                        @change="changeTareTime"
                        :clearable="true"
                    >
                    </el-date-picker>
                </div>
                <div class="query-item">
                    <label class="name">场站：</label>
                    <el-select
                        v-model="query.station_id"
                        placeholder="请选择"
                        clearable
                        collapse-tags
                    >
                        <el-option
                            v-for="item in stationList"
                            :key="item.station_id"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
            </template>
            <template #customTable>
                <div class="table-head">
                    <table class="t-table" cellpadding="0" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="width: 1.2rem" rowspan="3">
                                    日期
                                </th>
                                <template v-if="tableList.length>0">
                                    <th
                                        v-for="(v,k) in tableList[0].titleCols"
                                        :key="'cols'+ k"
                                        :colspan="v"
                                        style="width: 1.2rem"
                                    >
                                        {{ k }}
                                    </th>
                                </template>
                            </tr>
                            <template v-if="tableList.length>0">
                                <tr>
                                    <th
                                        v-for="(item,index) in tableList[0].list"
                                        :key="'cola'+ index"
                                        style="width: 1.2rem"
                                    >
                                        {{ item.material_name }}
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        v-for="(item,index) in tableList[0].list"
                                        :key="'colb'+ index"
                                        style="width: 1.2rem"
                                    >
                                        数量（T）
                                    </th>
                                </tr>
                            </template>
                        </thead>
                    </table>
                </div>
                <div class="table-body">
                    <table
                        v-if="tableList.length>0"
                        class="t-table"
                        cellpadding="0"
                        cellspacing="0"
                    >
                        <tbody>
                            <template v-for="(item, index) in tableList">
                                <tr :key="index">
                                    <td style="width: 1.2rem">
                                        {{ item.data_date.substring(0, 11) }}
                                    </td>
                                    <td
                                        v-for="(val, index1) in item.list"
                                        :key="index+ 'colc'+ index1"
                                        style="width: 1.2rem"
                                    >
                                        {{ val.checked_net_weight }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <div class="el-table__empty-text" v-if="tableList.length == 0"></div>
                </div>
            </template>
        </reportTable>
    </div>
</template>

<script>
import reportTable from '@/components/reportTable/reportTable.vue';
import qs from 'qs';
import utils from '@/utils/util';
export default {
    components: { reportTable },
    data() {
        return {
            loading: false,
            query: {
                startTareTime: '',
                endTareTime: '',
                station_id: '',
            },
            monthDate: '',
            tareTime: '',
            stationList: [],
            totalData: {},
            tableList: [],
            // 时间分批大小 ：天
            limit: 10,
            // 进度条
            percentage: 100,
            // 合并单元格配置
            mergeCells: {},
            // 查询分组
            queryArr: null,
        };
    },
    created() {
        this.monthDate = utils.timeFormat(new Date(), 'yyyy-MM');
        this.getStationList();
    },
    methods: {
        changeTareTime(val) {
            if (val) {
                this.query.startTareTime = utils.timeFormat(new Date(val), 'yyyy-MM-01');
                const maxDates = this.getMonthDays(val);
                this.query.endTareTime = utils.timeFormat(new Date(val), 'yyyy-MM-' + maxDates);
            } else {
                this.query.startTareTime = '';
                this.query.endTareTime = '';
            }
        },
        // 自定义查询时间
        customDateMethod(params) {
            params.startTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-01');
            const maxDates = this.getMonthDays(this.monthDate);
            params.endTime = utils.timeFormat(new Date(this.monthDate), 'yyyy-MM-' + maxDates);
        },
        getMonthDays(dateStr) {
            const d = new Date(dateStr);
            return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
        },
        // 获取场站列表
        async getStationList() {
            const data = await this.$axios.get('/interfaceApi/baseinfo/stationmanger/get_station_list');
            if (data.length > 0) {
                this.stationList = data;
            }
        },
        // 报表生成完成
        async generateComplete(params) {
            try {
                this.loading = true;
                this.tableList = [];
                this.mergeCells = {};
                this.percentage = 0;
                const pm = Object.assign({}, this.query);
                pm.startTime = params.startTime;
                pm.endTime = params.endTime;
                const listUrl = `/interfaceApi/report/purchase_day_report/data_list?${qs.stringify(pm)}`;
                const res = await this.$axios.get(listUrl);
                res.forEach(row => {
                    const titleCols = {};
                    row.list.forEach(item => {
                        if (titleCols[item.type_name]) {
                            titleCols[item.type_name]++;
                        } else {
                            titleCols[item.type_name] = 1;
                        }
                    });
                    row.titleCols = titleCols;
                });
                this.tableList = res;
                if (this.tableList.length > 0) {
                    const totalUrl = `/interfaceApi/report/purchase_day_report/data_total?${qs.stringify(pm)}`;
                    const totalData = await this.$axios.get(totalUrl);
                    this.tableList.push({
                        data_date: '合计',
                        list: totalData,
                    });
                }
                this.loading = false;
                this.percentage = 100;
            } catch (e) {
                this.$message.error(e.ErrorCode ? e.ErrorCode.Message : e.message);
            } finally {
                this.percentage = 100;
                this.loading = false;
            }
        },
    },
    destroyed() {
        this.queryArr = null;
    },
};
</script>
<style lang="stylus" scoped>
</style>
